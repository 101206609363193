import { DOCUMENT, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { v4 as uuid } from 'uuid';
import { LoadingComponent } from '../loading/loading.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  standalone: true,
  imports: [FormsModule, NgIf, FaIconComponent, LoadingComponent],
})
export class ModalComponent {
  faTimes = faTimes;

  id = uuid();
  @Input() loading = false;
  @Input() allowHide = true;
  @Input() wide = false;

  @Input() isOpen = false;
  @Input() allowClose = true;
  @Input() overflowVisible = false;

  @Output() dismissed = new EventEmitter<void>();

  showContent = false;
  showModal = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    if (this.isOpen) {
      console.log('open');
      this.show();
    }
  }

  show() {
    this.showContent = true;
    // const myDialog: HTMLDialogElement | null = this.document.querySelector(
    //   `#modal-${this.id}`
    // );
    // myDialog?.showModal();
    this.showModal = true;
    this.cdr.detectChanges();
  }

  hide() {
    if (this.allowClose) {
      this.showContent = false;
      this.dismissed.emit();
      // const myDialog: HTMLDialogElement | null = this.document.querySelector(
      //   `#modal-${this.id}`
      // );
      // myDialog?.close();
      this.showModal = false;
      this.cdr.detectChanges();
    }
  }
}
