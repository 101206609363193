<!-- <dialog id="modal-{{ this.id }}" class="modal">
  <form
    method="dialog"
    class="modal-box"
    [class.wide]="wide"
    [class.overflow-visible]="overflowVisible">
    @if (allowHide) {
      <button class="btn btn-sm btn-circle btn-ghost fixed right-2 top-2">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button>
    }
    @if (showContent) {
      <ng-content></ng-content>
    }
    @if (loading) {
      <div class="loading loading-dots"></div>
    }
  </form>
  @if (allowHide) {
    <form method="dialog" class="modal-backdrop">
      <button>close</button>
    </form>
  }
</dialog> -->

@if (showModal) {
  <div
    class="fixed z-[100] top-0 left-0 w-full h-full bg-black bg-opacity-50"
    (click)="hide()"></div>
  <div
    class="modal-c fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[100] max-h-full overflow-y-auto"
    [class.wide]="wide">
    <div class="card bg-white relative">
      <div class="card-body">
        @if (allowHide) {
          <button
            class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            type="button"
            (click)="hide()">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
        }
        @if (showContent) {
          <ng-content></ng-content>
        }
        @if (loading) {
          <div class="loading loading-dots"></div>
        }
      </div>
    </div>
  </div>
}
